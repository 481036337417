import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import {Typography,Button,Toolbar, Checkbox} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Paper from '@material-ui/core/Paper';
import Calendar from 'react-calendar';

import WebConfig from '../api/config';

import classNames from 'classnames';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';

import {styles} from '../layout/theme';
import Utils from '../api/api';

import '../assets/scss/calendar.scss';

// /var config = WebConfig[process.env.NODE_ENV];

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

class Booking extends Component {
  constructor(props){
    super(props);
    this.state = {
      services: [],
      data: [],
      queryData: [],
      start_date: new Date(),
      end_date: new Date(),
      date_view: new Date(),
      open: false
    }
  }
  componentDidMount(){
    var $this = this,
        date = new Date(),
        dates = Utils.getDaysInMonth(date.getMonth(),date.getFullYear());
    //console.log(dates);
    var start_date = new Date(dates[0]);
    var end_date = new Date(dates[dates.length-1]);
    start_date.setHours(0);
    start_date.setMinutes(0);
    start_date.setSeconds(0);
    end_date.setHours(23);
    end_date.setMinutes(59);
    end_date.setSeconds(59);

    Utils.getData('booking/date',start_date + '/' + end_date,function(data){
      //console.log(data);
      if(data.status==='success'){
        var arr = $this.state.data;
        $this.setState({
          data: arr.concat(data.results)
        },function(){
          var cells = document.querySelectorAll('.react-calendar__month-view__days time');
          for(var i=0,len=$this.state.data.length;i<len;i++){
            //var d = new Date($this.state.data[i].date).toISOString();
            var d = $this.state.data[i].date;
            var s = d.split('T')[0] + 'T00:00:00.000';
            var cell = document.querySelector('time[datetime="'+s+'"]');
            if(cell!==null){
              var span = cell.parentNode.querySelector('span');
              if(span==null){
                span = document.createElement('span');
                span.innerText = 1;
                cell.parentNode.appendChild(span);
              }else{
                span.innerText = parseInt(span.innerText) + 1;
              }
            }
          }
        });
      }
    });

    Utils.getListData('services','all',100,'0,0','',1,function(data){
      if(data.status==='success'){
        $this.setState({
          services: data.results
        });
      }
    });

  }
  handleClickOpen() {
    this.setState({ open: true });
  }
  handleClose() {
    this.setState({ open: false });
  }
  handleClickDay(e){
    var $this = this,
        arr = [];
    this.state.data.map((x,i)=>{
      Utils.isSameDate(new Date(x.date),new Date(e)) && arr.push(x);
    });
    this.setState({queryData:arr},function(){
      arr.length && $this.handleClickOpen();
    });
  }
  updateStatus(item,index){
    var $this = this,
        arr = this.state.queryData;
    item.status = item.status==0?1:0;
    Utils._update('booking',item,function(res){
      arr[index] = item;
      $this.setState({queryData: arr});
    });
  }
  render() {
    const classes = this.props.classes;
    const $this = this;

    return (
      <div className="media-page callendar-page">
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.heading} variant="h5">Lịch đặt dịch vụ</Typography>
        </Toolbar>
        <Paper className={classNames(classes.loadingOverlay, !this.state.loading && classes.hide)}>
          <CircularProgress className={classes.progressLoading}></CircularProgress>
        </Paper>
        <Calendar onClickDay={(e)=>this.handleClickDay(e)} className="fluid-calendar" tileClassName={({ date, view }) => (date.getDate() === new Date().getDate() && date.getMonth() === new Date().getMonth()) ? 'react-calendar__tile--active' : null} prevLabel={<KeyboardArrowLeft/>} prev2Label={<FirstPage/>} nextLabel={<KeyboardArrowRight/>} next2Label={<LastPage/>}>
        </Calendar>
        <Dialog open={this.state.open} onClose={()=>this.handleClose()} TransitionComponent={Transition}>
          <Table className={classes.tableResponsive}>
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell>STT</TableCell>
                <TableCell>Khách</TableCell>
                <TableCell>Ngày đặt</TableCell>
                {/* <TableCell>Ngày dự định sử dụng dịch vụ</TableCell> */}
                <TableCell>Số điện thoại</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Dịch vụ</TableCell>
                <TableCell>Ghi chú</TableCell>
                <TableCell>Xác nhận</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {
              $this.state.queryData.map((x,i)=>{
                return (
                  <TableRow key={i}>
                    <TableCell>{i+1}</TableCell>
                    <TableCell>{x.fullname}</TableCell>
                    <TableCell>{Utils.getBeautyDate(x.date,'vn')}</TableCell>
                    {/* <TableCell>{Utils.getBeautyDate(x.dateuse,'vn')}</TableCell> */}
                    <TableCell>{x.phone}</TableCell>
                    <TableCell>{x.email}</TableCell>
                    <TableCell>
                    {
                      $this.state.services.map(n=>n._id==x.service?n['titleVN']:'')
                    }
                    </TableCell>
                    <TableCell>{x.note}</TableCell>
                    <TableCell>
                      <Checkbox checked={x.status==1} onChange={()=>this.updateStatus(x,i)}></Checkbox>
                    </TableCell>
                  </TableRow>
                )
              })
            }
            </TableBody>
          </Table>
          <div className={classes.cmd}>
            <Button variant="contained" color="primary" onClick={()=>this.handleClose()}>OK</Button>
          </div>
        </Dialog>
      </div>
    );
  }
}

Booking.propTypes = {
  classes: PropTypes.object.isRequired,
};
Booking.contextTypes = {
  router: PropTypes.object
};

export default withStyles(styles)(Booking);
